/**
 * Swap collapse trigger icon where applicable
 */
$(function() {
    $('[data-toggle="collapse"]').each(function(index, element) {
        var selector = window.bootstrap.Util.getSelectorFromElement(element);

        $(selector).on('show.bs.collapse', setCollapseIcon);
        $(selector).on('hide.bs.collapse', setCollapseIcon);
    });

    function setCollapseIcon() {
        var triggerArray = $.makeArray($('[data-toggle="collapse"][href="#' + this.id + '"], [data-toggle="collapse"][data-target="#' + this.id + '"]'));

        for (var i = 0; i < triggerArray.length; i++) {
            var $trigger = $(triggerArray[i]);

            if (!$trigger.data('moreIcon') || !$trigger.data('lessIcon')) {
                return;
            }
            var newIcon = $trigger.hasClass('collapsed') ? $trigger.data('moreIcon') : $trigger.data('lessIcon');

            setTimeout(function() {
                if ($trigger.hasClass('collapse-trigger')) {
                    var icon = $trigger.find('.collapse-trigger__icon');

                    if (icon.find('use').length) {
                        // if the browser supports external use
                        icon.find('use').attr('xlink:href', energia.svg_path + '#' + newIcon);
                    } else {
                        // if the browser does not support external use and is polyfilled
                        icon.children().replaceWith('<use xlink:href="' + energia.svg_path + '#' + newIcon + '"></use>');
                    }
                }
            }, 100);
        };
    }
});

