;(function($, window, document) {
    var pluginName = 'energiaTableSticky';
    var defaults = {};
    var initSelector = '.tbl.is-sticky';

    /**
     * TableSticky.
     *
     * @class
     * @param {HTMLElement} element - The HTML element the TableSticky should be bound to.
     * @param {Object} options - An option map.
     */
    function TableSticky(element, options) {
        this.element = element;
        this.$element = $(this.element);
        this.container = element.parentElement;
        this.$container = $(this.container);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;

        this.thead = this.container.querySelector('thead');
        this.tbody = this.container.querySelector('tbody');

        this._init();
    }

    /**
     * Initialize the plugin.
     *
     * @function _init
     * @private
     * @returns {void}
     */
    TableSticky.prototype._init = function() {
        var that = this;

        // Style container
        this.container.style.overflow = 'auto';
        this.container.style.position = 'relative';

        this.$container.on('scroll.energia.table-sticky', function() {
            that.thead.style.transform = 'translate3d(0,' + this.scrollTop + 'px,0)';
            var hTransform = 'translate3d(' + this.scrollLeft + 'px,0,0)';

            that.thead.querySelector('th').style.transform = hTransform;
            [].slice.call(that.tbody.querySelectorAll('tr > td:first-child'))
            .forEach(function(td) {
                td.style.transform = hTransform;
            });
        });

        var resizeTimeout;

        function resizeThrottler() {
            if (!resizeTimeout) {
                resizeTimeout = setTimeout(function() {
                    resizeTimeout = null;
                    that._relayout();
                }, 500);
            }
        }

        $(window).on('resize.energia.table-sticky', resizeThrottler, false);
        this._relayout();
    };

    /**
     * Timeout the position method.
     *
     * @function _positionTimeout
     * @private
     * @returns {void}
     */
    TableSticky.prototype._relayout = function() {
        var ths = [].slice.call(this.thead.querySelectorAll('th'));
        var tbodyTrs = [].slice.call(this.tbody.querySelectorAll('tr'));

        /**
         * Remove inline styles so we resort to the default table layout algorithm
         * For thead, th, and td elements, don't remove the 'transform' styles applied
         * by the scroll event listener
         */
        this.tbody.setAttribute('style', '');
        this.thead.style.width = '';
        this.thead.style.position = '';
        this.thead.style.top = '';
        this.thead.style.left = '';
        this.thead.style.zIndex = '';
        ths.forEach(function(th) {
            th.style.display = '';
            th.style.width = '';
            th.style.position = '';
            th.style.top = '';
            th.style.left = '';
        });
        tbodyTrs.forEach(function(tr) {
            tr.setAttribute('style', '');
        });
        [].slice.call(this.tbody.querySelectorAll('td'))
        .forEach(function(td) {
            td.style.width = '';
            td.style.position = '';
            td.style.left = '';
        });

        /**
         * Store width and height of each th
         * getBoundingClientRect()'s dimensions include paddings and borders
         */
        var thStyles = ths.map(function(th) {
            var rect = th.getBoundingClientRect();
            var style = document.defaultView.getComputedStyle(th, '');


            return {
                boundingWidth: rect.width,
                boundingHeight: rect.height,
                width: parseInt(style.width, 10),
                paddingLeft: parseInt(style.paddingLeft, 10)
            };
        });

        // Set widths of thead and tbody
        var totalWidth = thStyles.reduce(function(sum, cur) {
            return sum + cur.boundingWidth;
        }, 0);

        this.tbody.style.display = 'block';
        this.tbody.style.width = totalWidth + 'px';
        this.thead.style.width = totalWidth - thStyles[0].boundingWidth + 'px';

        // Position thead
        this.thead.style.position = 'absolute';
        this.thead.style.top = '0';
        this.thead.style.left = thStyles[0].boundingWidth + 'px';
        this.thead.style.zIndex = 10;

        // Set widths of the th elements in this.thead. For the fixed th, set its position
        ths.forEach(function(th, i) {
            th.style.width = thStyles[i].width + 'px';
            if (i === 0) {
                th.style.position = 'absolute';
                th.style.top = '0';
                th.style.left = -thStyles[0].boundingWidth + 'px';
            }
        });

        // Set margin-top for tbody - the fixed header is displayed in this margin
        this.tbody.style.marginTop = thStyles[0].boundingHeight + 'px';

        // Set widths of the td elements in this.tbody. For the fixed td, set its position
        tbodyTrs.forEach(function(tr) {
            tr.style.display = 'block';
            tr.style.paddingLeft = thStyles[0].boundingWidth + 'px';
            [].slice.call(tr.querySelectorAll('td'))
            .forEach(function(td, j) {
                td.style.width = thStyles[j].width + 'px';
                if (j === 0) {
                    td.style.position = 'absolute';
                    td.style.left = '0';
                }
            });
        });
    };

    /**
     * Destroy plugin.
     *
     * @function destroy
     * @returns {void}
     */
    TableSticky.prototype.destroy = function() {
        this.$container.off('scroll.energia.table-sticky');
        $(window).off('resize.energia.table-sticky');
    };

    /**
     * Create jQuery plugin.
     *
     * @param {Object} options - Extended options.
     * @listens energia.table-sticky
     * @returns {Array<TableSticky>} Array of TableStickies.
     */
    $.fn[pluginName] = function(options) {
        var args = Array.prototype.slice.call(arguments, 1);

        return this.each(function() {
            var instance = $.data(this, 'plugin_' + pluginName);

            if (!instance) {
                if (options === 'destroy') {
                    return;
                }

                $.data(this, 'plugin_' + pluginName, new TableSticky(this, options));
            } else if (typeof options === 'string') {
                instance[options].apply(instance, args);
            }
        });
    };

    /**
     * @param {Event} event
     * @event enhance.energia.table-sticky
     */
    $(document).on('enhance.energia.table-sticky', function(event) {
        $(event.target).find(initSelector)[pluginName]();
    });

    /**
     * @param {Event} even
     * @event destroy.energia.table-sticky
     */
    $(document).on('destroy.energia.table-sticky', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]('destroy');
    });
})(jQuery, window, document);

/**
* Auto-init the plugin on DOM ready.
* @fires enhance.energia.table-sticky
*/
$(function() {
    $(document).trigger('enhance.energia.table-sticky');
});

