/**
 * Inject all modals as immediate children of document.body
 */
$(function() {
    $('[data-toggle="modal"]').each(function(index, element) {
        var selector = window.bootstrap.Util.getSelectorFromElement(element);

        $(selector).on('show.bs.modal', injectIntoBody);
    });

    function injectIntoBody() {
        if (this.parentElement !== document.body) {
            document.body.appendChild(this);
        }
    }
});

