/**
 * Show toast message
 * @param  {message} message Message text
 * @return {void}
 */
function toast(message) {
    var html = '<div class="toast is-visible">' + message + '</div>';

    $('body').append(html);
    $('.toast').delay(5000).fadeOut(1000, function() {
        $(this).remove();
    });

    return message;
}

window.toast = toast;

