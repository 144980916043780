/* globals svg4everybody */

svg4everybody();

;(function() {
    /**
     * Icon
     * @class
     */
    function Icon() {}

    /**
     * Get the HTML for an icon.
     * @method render
     * @static
     * @param   {String} name Icon name
     * @param   {String} className Additional class for icons
     * @returns {String} HTML for an icon
     */
    Icon.render = function(name, className) {
        className = typeof className !== 'undefined' ? ' ' + className : '';

        if (typeof name === 'undefined') {
            return;
        }

        return '<svg class="icon' + className + '"><use xlink:href="' + energia.svg_path + name + '"></use></svg>';
    };

    energia = typeof energia !== 'undefined' ? energia : {};
    energia.icon = Icon;
})();

