;(function($, window, document) {
    var pluginName = 'energiaLayout';
    var defaults = {
        closedClass: 'is-closed',
        break: 800
    };
    var initSelector = '.js-layout';

    /**
     * Layout
     * @class
     * @param {HTMLElement} element The HTML element the Layout should be bound to
     * @param {Object} options An option map
     */
    function Layout(element, options) {
        this.element = element;
        this.$element = $(this.element);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.trigger = $('.layout-trigger');
        this.isClosed = false;

        this.init();
    }

    /**
     * Initialize the plugin.
     * @method init
     * @returns {void}
     */
    Layout.prototype.init = function() {
        var that = this;

        this.isClosed = window.localStorage.getItem('isLayoutClosed');

        this.isClosed = this.isClosed == 'true';
        this.toggle();

        this.trigger.on('click.energia.layout', function(e) {
            e.preventDefault();

            that.isClosed = !that.isClosed;
            that.toggle();
        });

        $(window).on('resize.energia.layout', this._resizeHandler.bind(this));
    };

    /**
     * Toggle aside
     * @param {Boolean} isInitial If it is page load
     * @return {[type]} [description]
     */
    Layout.prototype.toggle = function() {
        this.$element.toggleClass(this.options.closedClass, this.isClosed);
        window.localStorage.setItem('isLayoutClosed', this.isClosed);
    };

    /**
     * Close aside if on mobile.
     * @method _resizeHandler
     * @private
     * @returns {void}
     */
    Layout.prototype._resizeHandler = function() {
        this.isClosed = true;

        if ($(window).width() < this.options.break) {
            this.$element.addClass(this.options.closedClass);
            window.localStorage.setItem('isLayoutClosed', this.isClosed);
        }
    };

    /**
     * Destroy the plugin instance.
     * @method destroy
     * @returns {void}
     */
    Layout.prototype.destroy = function() {
        this.$element.off('click.energia.layout');
        this.$element.removeClass(this.options.closedClass);

        this.$element.removeData('plugin_' + pluginName);
    };

    /**
     * Create jQuery plugin.
     * @param {Object} options Extended options
     * @listens energia.layout
     * @returns {Array<Layout>} Array of Layouts
     */
    $.fn[pluginName] = function(options) {
        var args = Array.prototype.slice.call(arguments, 1);

        return this.each(function() {
            var instance = $.data(this, 'plugin_' + pluginName);

            if (!instance) {
                $.data(this, 'plugin_' + pluginName, new Layout(this, options));
            } else if (typeof options === 'string') {
                instance[options].apply(instance, args);
            }
        });
    };

    /**
     * @param {Event} event
     * @event enhance.energia.layout
     */
    $(document).on('enhance.energia.layout', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]();
    });

    /**
     * @param {Event} event
     * @event destroy.energia.layout
     */
    $(document).on('destroy.energia.layout', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]('destroy');
    });
})(jQuery, window, document);

/**
 * Auto-init the plugin on DOM ready.
 * @fires enhance.energia.layout
 */
$(function() {
    $(document).trigger('enhance.energia.layout');
});

