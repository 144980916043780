$(function() {
    var lazyloadResizeTimer;

    $('.lazyload').on('load', function() {
        clearTimeout(lazyloadResizeTimer);
        lazyloadResizeTimer = setTimeout(function() {
            $(window).trigger('resize');
        }, 250);
    });
});

/**
 * Disable window scrolling
 * @param  {Boolean} hideScrollbar If scrollbar should be hidden
 * @returns {void}
 */
function disableScroll(hideScrollbar) {
    if (!$('body').hasClass('scroll-disabled')) {
        var curScroll = $(window).scrollTop();

        $('body').addClass('scroll-disabled');
        if (hideScrollbar) {
            $('body').addClass('scroll-disabled--hide-scrollbar');
        }
        $('body').css('top', -curScroll);
    }
}

/**
 * Enable window scrolling
 * @returns {void}
 */
function enableScroll() {
    var bodyScroll = parseInt($('body').css('top'), 10);

    $('body').removeClass('scroll-disabled scroll-disabled--hide-scrollbar');
    if (bodyScroll) {
        $(window).scrollTop(-bodyScroll);
        $('body').css('top', 0);
    }
}

window.disableScroll = disableScroll;
window.enableScroll = enableScroll;

$.fn.isOnScreen = function(threshold) {
    var win = $(window);

    var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
    };

    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();

    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top + threshold || viewport.top > bounds.bottom));
};

/**
 * Trigger element if hash is in url
 * @returns {void}
 */
function hashTrigger() {
    var hashes;
    var hash = window.location.hash;

    if (!hash) {
        return;
    }

    hashes = hash.split('/');

    $.each(hashes, function(index, value) {
        if (value.indexOf('?') !== 0 && value.length != 1 && value.length != 0) {
            if (value.charAt(0) != '#') {
                value = '#' + value;
            }
            var first = $('[data-target="' + value + '"][data-toggle],[href="' + value + '"][data-toggle], .sidemenu__link[href="' + value + '"], .tabs__link[href="' + value + '"], .tabs__accordion-link[href="' + value + '"]').first();

            first.trigger('click');
        }
    });
}
window.hashTrigger = hashTrigger;

$(function() {
    hashTrigger();
});

/**
ChildNode.remove() polyfill.
From: https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
*/
(function(arr) {
    arr.forEach(function(item) {
        item.remove = item.remove || function() {
            this.parentNode.removeChild(this);
        };
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

